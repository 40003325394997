function ImgNoFound({ title }) {
  const stlye = {
    height: "100%",
    minHeight: "100%",
    minWidth: "100% ",
    backgroundImage:
      "linear-gradient(#000,#0005), url(/img/404-page-not-found.png)",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div style={stlye} className="movieExpand p-1 pb-5 rounded text-white">
      {title?.substring(0, 10)}...
    </div>
  );
}

export default ImgNoFound;
