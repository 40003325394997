import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./Title.css";

const Title = () => {
  const dispatch = useDispatch();

  function onClick() {
    window.scrollTo(0, 0);
    dispatch({ type: "REMOVE_MOVIES" });
  }

  return (
    <Link
      className="d-flex flex-row align-items-center justify-content-center title-container"
      to={`/`}
      onClick={onClick}
      style={{ textDecoration: "none" }}
    >
      <i className="void-icon me-2" />
      <div className="d-flex flex-column text-start">
        <span
          title="Web"
          className="navbar-brand text-white p-0 m-0 font-monospace"
          style={{ fontSize: "1.5rem" }}
        >
          Void
        </span>
        <span className="title-description fst-italic">
          A journey into the unknown
        </span>
      </div>
    </Link>
  );
};

export default Title;
