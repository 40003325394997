import "../App.css";
//import Puntaje from "./Puntaje";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import NotFounded from "../components/NotFound";
import { useSelector } from "react-redux";

const MovieDetails = () => {
  const { id } = useParams();
  const [pelicula, setPelicula] = useState(undefined);
  const { movies } = useSelector((state) => state);
  //const [video, setVideo] = useState(undefined);

  async function getPelicula(url, setData) {
    try {
      const response = await axios({
        method: "get",
        url,
        params: {
          //language: "es-MX",
          api_key: process.env.REACT_APP_TMBD_API_KEY,
        },
      });
      setData(response.data);
    } catch (error) {
      setData(null);
    }
  }

  useEffect(() => {
    getPelicula(`https://api.themoviedb.org/3/movie/${id}`, setPelicula);
  }, [id]);

  /*useEffect(() => {
    if (pelicula)
      getPelicula(`https://api.themoviedb.org/3/movie/${id}/videos`, setVideo);
    // eslint-disable-next-line
  }, [pelicula]);*/

  return (
    <>
      {pelicula ? (
        <>
          <div
            style={{
              backgroundImage: `radial-gradient(#00000000, #000000F0), url(https://image.tmdb.org/t/p/original${pelicula.backdrop_path})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100vw",
              height: "100vh",
              zIndex: "-1",
            }}
            className="fixed-top"
          ></div>
          <div style={{ marginTop: "5rem" }} className="container p-0 m-0">
            <div
              style={{
                //backgroundColor: "#02020290",
                backgroundImage: "linear-gradient(#000A,#000)",
                marginTop: "60vh",
                minHeight: "40vh",
              }}
              className="d-flex flex-column text-white mx-0 p-2 py-4 px-4 vw-100"
            >
              <h1 className="me-5">{pelicula.title}</h1>
              <h2 className="my-2 fs-5">{pelicula.tagline}</h2>
              <ul className="d-flex flex-row flex-wrap p-0 m-0 align-items-center">
                <span
                  title="Release Date"
                  className={`me-3 ${
                    pelicula.status === "Released"
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  {pelicula.release_date}
                </span>

                {pelicula.genres.map((gen, index) => (
                  <li
                    title="Genre"
                    key={"genres" + pelicula.id + index}
                    style={{ listStyle: "none", color: "#ff34d1" }}
                    className="me-3"
                  >
                    {gen.name}
                  </li>
                ))}

                <span title="Popularity" className={"me-3 text-info"}>
                  <i className="fas fa-fire-alt me-2"></i>
                  {pelicula.popularity}
                </span>

                <span title="Runtime" className={"me-3 text-info"}>
                  <i className="far fa-clock me-2"></i>
                  {pelicula.runtime}
                </span>

                {pelicula.vote_count > 500 && (
                  <span
                    title="Vote Average"
                    className={`me-3 ${
                      pelicula.vote_average >= 7
                        ? "text-success"
                        : pelicula.vote_average >= 5
                        ? "text-warning"
                        : "text-danger"
                    }`}
                  >
                    <i className="far fa-star me-1"></i>
                    {pelicula.vote_average}
                  </span>
                )}

                {/* Back to Home/Search */}
                {movies ? (
                  <button
                    onClick={() => window.history.back()}
                    title="back"
                    className="d-flex flex-wrap flex-row align-items-center btn btn-outline-light"
                  >
                    Next Movie
                    <i className="fas fa-arrow-right ms-2 "></i>
                  </button>
                ) : (
                  <Link
                    to="/"
                    title="Go to home"
                    className="d-flex flex-wrap flex-row align-items-center btn btn-outline-light"
                  >
                    Other Movies
                    <i className="fas fa-arrow-right ms-2 "></i>
                  </Link>
                )}
              </ul>

              <p className="mt-2">{pelicula.overview}</p>

              <ul className="d-flex flex-row flex-wrap p-0 m-0">
                {pelicula.production_companies.map((gen, index) => (
                  <li
                    title="Production Company"
                    key={"production_companies" + pelicula.id + index}
                    style={{ listStyle: "none" }}
                    className="me-3 text-muted"
                  >
                    {gen.name}
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className="d-flex justify-content-around align-items-center flex-column bg-dark p-1">
              <span
                style={{ width: "4Rem", height: "4Rem" }}
                className="rounded-circle bg-success m-1 d-flex justify-content-center text-white align-items-center"
              >
                <i className="fas fa-star"></i>
                {pelicula.vote_average}
              </span>
              <span className="m-1 hadow bg-white p-1 rounded">
                {pelicula.release_date}
              </span>
            </div>*/}
          </div>
          <div className="d-flex flex-wrap justify-content-around align-items-center">
            {/* <div className="shadow opacityAnim d-flex flex-column p-2 my-2">
              {video &&
                (video.results.length ? (
                  <iframe
                    title={video.results[0].name}
                    width="420"
                    height="315"
                    className=""
                    src={`https://www.youtube.com/embed/${video.results[0].key}`}
                  ></iframe>
                ) : (
                  <></>
                ))}
            </div> */}
            {/* <img
              style={{ maxHeight: "35Rem" }}
              title={pelicula.title}
              alt={pelicula.title}
              className="img-fluid py-4"
              src={`https://image.tmdb.org/t/p/w500${pelicula.poster_path}`}
            /> */}
          </div>
        </>
      ) : pelicula === null ? (
        <NotFounded title="Movie not found :c" />
      ) : (
        <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-white" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default MovieDetails;
