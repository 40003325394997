import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const usePeliculas = () => {
  const [isMount, setIsMount] = useState(false);
  const { page, maxPage, movies, filterTitle, filterYear } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  async function getPeliculas(page) {
    try {
      const response = await axios({
        method: "get",
        url: getUrl(filterTitle, filterYear),
        params: {
          //language: "es-MX",
          page,
          api_key: process.env.REACT_APP_TMBD_API_KEY,
        },
      });
      if (page === 1) {
        dispatch({ type: "SET_MOVIES", payload: response.data });
      } else {
        dispatch({ type: "ADD_MOVIES", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "SET_MOVIES", payload: { movies: null } });
    }
  }

  useEffect(() => {
    if (movies === undefined) {
      getPeliculas(1);
    }
    // eslint-disable-next-line
  }, [filterTitle, filterYear, movies]);

  useEffect(() => {
    if (!isMount) return setIsMount(true);
    if (page && page < maxPage) getPeliculas(page);
    // eslint-disable-next-line
  }, [page]);

  function infiniteScroll() {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    // console.log(
    //   " [scrollHeight, clientHeight, scrollTop] " +
    //     [scrollHeight, clientHeight, scrollTop]
    // );

    let margin = 200;
    if (window.innerWidth < 576) margin = 700;

    if (clientHeight + Math.ceil(scrollTop) >= scrollHeight - margin) {
      // console.log(" [clientHeight + scrollTop] " + [clientHeight + scrollTop]);
      dispatch({ type: "NEXT_PAGE_MOVIES" });
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", infiniteScroll);
    return () => {
      window.removeEventListener("scroll", infiniteScroll);
    };
    // eslint-disable-next-line
  }, []);

  // State
  const isLoading = movies === undefined;
  const hasError = movies === null;
  const noResults = movies && !movies.length;

  return {
    movies,
    filterTitle,
    filterYear,
    isLoading,
    hasError,
    noResults,
  };
};

function getUrl(movieFilterTitle, movieFilterDate) {
  const url = `https://api.themoviedb.org/3/`;
  /*if (movieFilterScore) {
    return (
      `${url}discover/movie?sort_by=vote_average.desc&vote_count.gte=1000` +
      `&vote_average.lte=${movieFilterScore * 2}` +
      `&primary_release_year=${movieFilterDate}`
    );
  }*/
  if (movieFilterTitle && movieFilterTitle.length) {
    return `${url}search/movie?query=${movieFilterTitle}`;
  } else {
    return `${url}discover/movie?primary_release_year=${movieFilterDate}`;
  }
}

export default usePeliculas;
