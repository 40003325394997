import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SearchInput.module.css";

const SearchInput = () => {
  const { filterTitle } = useSelector((state) => state);
  const [searchTerm, setSearchTerm] = useState(""); // Maneja el valor del campo de búsqueda
  const isCategories = window.location.pathname === "/categories";
  const dispatch = useDispatch();

  const onSubmitSearchBtn = (e) => {
    e.preventDefault();
    // Aquí puedes hacer el dispatch o lo que necesites al enviar la búsqueda
    console.log("Searching for:", searchTerm);
  };

  // Función para limpiar el campo de búsqueda
  const clearSearch = () => {
    setSearchTerm("");
    dispatch({ type: "PLUS_RESET_CAROUSEL" });
    dispatch({ type: "SET_FILTER_TITLE_MOVIES", payload: "" });
  };

  function goToCategories() {
    if (searchTerm)
      dispatch({ type: "SET_FILTER_TITLE_MOVIES", payload: searchTerm });

    if (isCategories) return;
    window.location.href = "/categories?search=" + searchTerm;
  }

  function keepFocus() {
    const list = document.getElementsByClassName("search-input");
    for (let i = 0; i < list.length; i++) list[i].focus();
  }

  useEffect(() => {
    if (!isCategories) return;

    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get("search");

    if (search) setSearchTerm(search);
    keepFocus();
  }, []);

  useEffect(() => {
    if (!isCategories) clearSearch();
  }, [isCategories]);

  function handleSearch(ev) {
    const { value } = ev.target;
    setSearchTerm(value);

    dispatch({ type: "PLUS_RESET_CAROUSEL" });
    dispatch({ type: "SET_FILTER_TITLE_MOVIES", payload: value });
  }

  return (
    <form onSubmit={onSubmitSearchBtn} className="position-relative">
      <label
        htmlFor="search-input"
        className="fas fa-search position-absolute text-white"
        style={{
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
      ></label>
      <input
        id="search-input"
        title="Search"
        value={filterTitle}
        onChange={handleSearch} // Actualiza el estado con cada cambio en el input
        className={`${styles.cSearchNavBarBtn} rounded-pill border-0 px-5 py-1 search-input`}
        aria-label="Search"
        placeholder="Search"
        type="text"
        onFocus={goToCategories}
      />
      {searchTerm && (
        <i
          className="fas fa-times position-absolute text-white"
          style={{
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            padding: "1rem",
          }}
          onClick={clearSearch}
        ></i>
      )}
    </form>
  );
};

export default SearchInput;
