function NotFounded({ title = "Page not found" }) {
  return (
    <div className="vw-100 vh-100 d-flex align-items-center justify-content-center flex-column">
      <h1 className="bg-danger text-white rounded-bottom px-2">{title}</h1>
      <img src="/img/404-page-not-found.png" alt={title} title={title} />
    </div>
  );
}

export default NotFounded;
