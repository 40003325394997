import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { handleOn } from "./Menu";

function Contact({ open, setOpen }) {
  function ajustBackdropZIndex() {
    const backdrop = document.getElementsByClassName("modal-backdrop");
    backdrop[0].style = "z-index: 1";
    setOpen(true);
  }

  return (
    <>
      <Link
        className={`nav-link mx-1 p-1 px-2 ${handleOn(
          open
        )} w-100 text-lg-center`}
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        onClick={(e) => ajustBackdropZIndex()}
      >
        <i className="fas fa-feather-alt shadow"></i>
        {/* <span className={`d-inline ${!open && "d-lg-none"} ms-2`}> */}
        <span className={`d-inline ms-2`}>Contact</span>
      </Link>

      <div
        className="modal fade"
        id="staticBackdrop"
        tabIndex="-1"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdrop">
                Contact
              </h5>
              <button
                title="Close"
                type="button"
                className="btn-close rounded"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setOpen(false)}
              ></button>
            </div>
            <div className="modal-body text-center py-5">
              <BtnLink
                title="Personal Website"
                icon="fas fa-globe"
                to="https://agus.vercel.app"
              />
              <BtnLink
                title="LinkedIn"
                icon="fab fa-linkedin-in"
                to="https://linkedin.com/in/iagis/"
              />
              <BtnLink
                title="GitHub"
                icon="fab fa-github"
                to="https://github.com/iAgis"
              />
            </div>
            <div className="modal-footer">
              <button
                title="Close"
                type="button"
                className="btn btn-outline-secondary rounded"
                data-bs-dismiss="modal"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;

function BtnLink({ icon, title, to = "#" }) {
  return (
    <a
      title={title}
      href={to}
      rel="noreferrer"
      target="_blank"
      style={{ height: "3.5rem", width: "3.5rem" }}
      className="btn btn-outline-dark mx-4 rounded-circle border-bottom border-dark border-0 shadow fs-3"
    >
      <i className={icon}></i>
    </a>
  );
}
