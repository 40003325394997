import { useDispatch, useSelector } from "react-redux";

function FilterYear() {
  const { filterYear } = useSelector((state) => state);
  const dispatch = useDispatch();
  const resetCarrousel = () => dispatch({ type: "PLUS_RESET_CAROUSEL" });

  function handleYearChange(isPlus) {
    resetCarrousel();
    dispatch({ type: "SET_FILTER_TITLE_MOVIES", payload: "" });

    if (!isPlus) {
      if (filterYear > 1930) {
        dispatch({ type: "SET_FILTER_DATE_MOVIES", payload: filterYear - 1 });
      }
    } else {
      if (filterYear < new Date().getFullYear()) {
        dispatch({ type: "SET_FILTER_DATE_MOVIES", payload: filterYear + 1 });
      }
    }
  }

  return (
    <div className="d-flex flex-column align-items-center text-white">
      <div className="d-flex flex-row align-items-center fs-6 rounded py-2 c-bg-primary rounded-pill">
        <button
          title="-1 Year"
          className={`rounded-pill mx-2 border-0 ${
            filterYear > 1930 ? "" : "disabled"
          }`}
          onClick={() => handleYearChange(false)}
          disabled={filterYear <= 1930}
        >
          <i className="fas fa-arrow-left me-1"></i>
          {filterYear - 1}
        </button>
        <div className="mx-2 fs-5">
          <i className="fas fa-calendar-alt me-1"></i> {filterYear}
        </div>
        <button
          title="+1 Year"
          className={`rounded-pill mx-2 border-0 ${
            filterYear < new Date().getFullYear() ? "" : "disabled"
          }`}
          onClick={() => handleYearChange(true)}
          disabled={filterYear >= new Date().getFullYear()}
        >
          {filterYear + 1}
          <i className="fas fa-arrow-right ms-1"></i>
        </button>
      </div>
    </div>
  );
}

export default FilterYear;
