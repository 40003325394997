const initState = {
  filterTitle: "",
  filterYear: new Date().getFullYear(),
  movies: undefined,
  carrouselReset: 0,
};

function moviesReducer(state = initState, action) {
  switch (action.type) {
    case "ADD_MOVIES":
      /*console.log(action.payload.page, state.page);
      if (action.payload.page < state.page) return state;*/
      return {
        ...state,
        ...{
          movies: [...state.movies, ...action.payload.results],
          maxPage: action.payload.total_pages,
        },
      };
    case "REMOVE_MOVIES":
      return initState;
    case "SET_MOVIES":
      return {
        ...state,
        page: action.payload.page,
        movies: action.payload.results,
        maxPage: action.payload.total_pages,
      };
    case "NEXT_PAGE_MOVIES":
      return { ...state, page: ++state.page };
    case "SET_FILTER_DATE_MOVIES":
      return { ...state, filterYear: action.payload, movies: undefined };
    case "REMOVE_FILTER_DATE_MOVIES":
      return { ...initState };
    case "SET_FILTER_TITLE_MOVIES":
      return { ...state, filterTitle: action.payload, movies: undefined };
    case "REMOVE_FILTER_TITLE_MOVIES":
      return { ...initState };
    case "PLUS_RESET_CAROUSEL":
      return { ...state, carrouselReset: ++state.carrouselReset };
    default:
      return state;
  }
}

export default moviesReducer;
