import Peliculas from "../../Peliculas/Peliculas";
import FilterYear from "../components/FilterYear";
import SearchInput from "../../NavBar/components/SearchInput/SearchInput";

function Categories() {
  // if is less at lg show search
  const showSearch = window.innerWidth < 992;

  return (
    <div className="d-flex flex-column align-items-center text-white gap-3">
      <h1 className="text-center c-bg-primary rounded-pill px-4 py-2 pb-3">
        Search your favorite movie!
      </h1>
      {showSearch && <SearchInput />}
      <FilterYear />
      <hr />
      <Peliculas />
    </div>
  );
}

export default Categories;
