import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Carousel.module.css";

function Carousel() {
  const { movies, carrouselReset } = useSelector((state) => state);
  const [indexMovie, setIndexMovie] = useState(0);
  const [imgFade, setImgFade] = useState("");
  const [imgBanner, setImgBanner] = useState(undefined);

  useEffect(() => {
    const interval = setInterval(() => {
      setImgFade("");
      setIndexMovie((prev) => prev + 1);
    }, 3000);
    setIndexMovie(0);
    return () => clearInterval(interval);
  }, [carrouselReset]);

  function newBannerImage() {
    const backdrop = getMovieBackdrop_path(indexMovie, movies, setIndexMovie);
    if (backdrop) {
      setImgBanner("https://image.tmdb.org/t/p/original" + backdrop);
    } else {
      setImgBanner("");
    }
  }

  useEffect(
    () => newBannerImage(),
    // eslint-disable-next-line
    [indexMovie]
  );

  useEffect(() => {
    if (movies) newBannerImage();
    // eslint-disable-next-line
  }, [movies]);

  return (
    <>
      {imgBanner && (
        <img
          style={{
            height: "100vh",
            objectFit: "cover",
            zIndex: "-1",
          }}
          onLoad={() => {
            setImgFade(styles.heroOpacityAnim);
          }}
          src={imgBanner}
          className={`d-block vw-100 position-absolute ${imgFade}`}
          // className={`d-block vw-100 position-absolute ${imgFade}`}
          alt="..."
        />
      )}

      <div
        style={{
          height: "100vh",
          backgroundImage: "linear-gradient(to bottom, #00000000, #000000ff)",
          zIndex: "-1",
        }}
        className={`container-fluid shadow vw-100 position-relative`}
      ></div>
    </>
  );
}

export default Carousel;

function getMovieBackdrop_path(currIndex, movies, setCurrIndex) {
  if (!movies || !movies.length) return "";
  else {
    let movieIndex = currIndex;
    if (currIndex >= movies.length) {
      setCurrIndex(0);
      movieIndex = 0;
    }
    const backdrop_path = movies[movieIndex].backdrop_path;
    return backdrop_path ? backdrop_path : "";
  }
}
