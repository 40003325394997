import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ImgFound from "../components/img/found/ImgFound";
import ImgNoFound from "../components/img/noFound/ImgNoFound";
import Col from "../components/struct/Col";

function Movie({ id, title, poster_path }) {
  const to = `/movie/${id}`;
  const style = { textDecorationLine: "none" };
  const Img = poster_path ? ImgFound : ImgNoFound;

  return (
    <Col>
      <Link to={to} style={style}>
        <Img title={title} posterPath={poster_path} />
      </Link>
    </Col>
  );
}

export default Movie;
