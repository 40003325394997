import Carousel from "./components/Carousel";
import ViewBox from "./components/ViewBox";
import Categories from "./views/Categories";
import Home from "./views/Home";

function Hero({ page }) {
  const isHome = page === "Home";
  const View = isHome ? Home : Categories;

  return (
    <>
      <Carousel />

      <ViewBox applyTop={isHome}>
        <View />
      </ViewBox>
    </>
  );
}

export default Hero;
