import Box from "../components/struct/Box";

function NoResults({}) {
  return (
    <Box>
      <h3 className="text-white p-1 bg-danger rounded text-center">
        Sin resultados de busqueda :C
      </h3>
    </Box>
  );
}

export default NoResults;
