function ImgFound({ title, posterPath }) {
  const style = {
    objectFit: "cover",
    height: "100%",
    minHeight: "100%",
    minWidth: "100% ",
  };

  return (
    <img
      style={style}
      title={title}
      alt={title}
      className="img-fluid c-rounded movieExpand w-100"
      src={`https://image.tmdb.org/t/p/w500${posterPath}`}
    />
  );
}

export default ImgFound;
