import Menu from "./components/Menu";
import Title from "./components/Title";
import useNavBar from "./useNavBar";

const NavBar = ({ ...rest }) => {
  const { url, collapse, isScrolling, dispatch, setCollapse } = useNavBar(rest);
  const menuParams = { url, collapse, dispatch, setCollapse };

  let style = {
    transition: ".4s",
    // Gradient
    backgroundImage: "linear-gradient(to bottom, #000000f0, #00000000)",
  };
  if (isScrolling)
    style = {
      ...style,
      backgroundColor: "#000000",
      // border bottom
      borderBottom: "1px solid #a64bf470",
    };

  return (
    <>
      <nav style={style} className={`navbar navbar-expand-lg fixed-top px-3`}>
        <Title />
        <Menu {...menuParams} />
        {/* <Menu {...menuParams} /> */}
      </nav>
    </>
  );
};

export default NavBar;
