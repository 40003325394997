import Box from "../components/struct/Box";

function ServerError({}) {
  return (
    <Box>
      <h3 className="text-white p-1 bg-danger rounded text-center">
        Server Error
      </h3>
    </Box>
  );
}

export default ServerError;
