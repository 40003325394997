import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Menu.module.css";
import SearchInput from "./SearchInput/SearchInput";

export function handleOn(on = false) {
  if (on) return styles.cNavBtnActive;
  return styles.cNavBtn;
}

const Menu = ({ ...rest }) => {
  const { url, collapse, dispatch, setCollapse } = rest;

  const isLink = (name) => url === `/${name}`;

  useEffect(() => {
    setCollapse(true);
  }, [url]);

  return (
    <>
      <button
        className="navbar-toggler c-bg-primary py-1 px-2 rounded-3"
        type="button"
        title={`${collapse ? "Expand" : "Collapse"} menu`}
        onClick={() => setCollapse((prev) => !prev)}
      >
        <span className="fas fa-bars text-white"></span>
      </button>
      {/* <div className={`${collapse ? "collapse" : ""} navbar-collapse`}> */}
      <ul
        //  className="navbar-nav mb-2 mb-lg-0"
        className={`${
          collapse ? "collapse" : " bg-white my-2 "
        } navbar-collapse navbar-nav mb-2 mb-lg-0 gap-4 gap-lg-0 p-4 py-lg-1 px-lg-0 c-rounded`}
        style={{ fontSize: ".8rem" }}
      >
        <li title="Home" className="nav-item w-lg-less-100 ms-lg-auto">
          <Link
            to={`/`}
            onClick={() => dispatch({ type: "REMOVE_MOVIES" })}
            className={`nav-link mx-lg-1 p-1 px-2 ${handleOn(
              isLink("")
            )} w-100 text-lg-center`}
          >
            <i className="fas fa-spa shadow"></i>
            <span className={`d-inline ms-2`}>Home</span>
          </Link>
        </li>
        <li title="Categories" className="nav-item w-lg-less-100 mx-lg-2">
          <Link
            to={`/categories`}
            onClick={() => dispatch({ type: "REMOVE_MOVIES" })}
            className={`nav-link mx-lg-1 p-1 px-2 ${handleOn(
              isLink("categories")
            )} w-100 text-lg-center`}
          >
            <i className="fas fa-list shadow"></i>
            <span className={`d-inline ms-2`}>Categories</span>
          </Link>
        </li>
        <li title="About us" className="nav-item w-lg-less-100 me-lg-2">
          <Link
            to={`/about-us`}
            className={`nav-link mx-lg-1 p-1 px-2 ${handleOn(
              isLink("about-us")
            )}  w-100 text-lg-center`}
          >
            <i className="fas fa-users shadow"></i>
            <span className={`d-inline ms-2`}>About Us</span>
          </Link>
        </li>
        <li title="Search" className="nav-item ms-lg-auto w-lg-less-100">
          <SearchInput />
        </li>
      </ul>
      {/* </div> */}
    </>
  );
};

export default Menu;

/* <div
style={{ backgroundColor: "#0005" }}
className="d-flex flex-row align-items-center fs-6 rounded py-2"
>
<button
  className="btn btn-outline-danger mx-2"
  onClick={() => setMovieFilterDate((prev) => prev - 1)}
>
  <i class="fas fa-arrow-left mx-2"></i>
</button>
{movieFilterDate}
<button
  className="btn btn-outline-success mx-2"
  onClick={() => setMovieFilterDate((prev) => prev + 1)}
>
  <i class="fas fa-arrow-right mx-2"></i>
</button>
</div> */
