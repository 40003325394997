import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Contact from "./components/Contact";

const useNavBar = (rest) => {
  const [url, setUrl] = useState(rest.location.pathname);
  const [collapse, setCollapse] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setUrl(rest.location.pathname);
  }, [rest.location.pathname]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10) return setIsScrolling(true);
      return setIsScrolling(false);
    });
  }, []);

  return { url, collapse, isScrolling, dispatch, setCollapse, Contact, Link };
};

export default useNavBar;
