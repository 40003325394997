//// Ej 13

import { useEffect } from "react";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container pt-5 pb-5">
      <div className="d-flex flex-column pt-5 w-100">
        {/* Person */}
        <div className="text-white d-flex flex-wrap flex-column w-100 align-items-center justify-content-center pb-5">
          <div className="d-flex flex-column">
            <img
              src="/img/Agus.png"
              alt="Agustín Lemes"
              style={{
                objectFit: "cover",
                height: "4rem",
                width: "10rem",
                objectPosition: "0 1%",
              }}
              className="img-fluid rounded m-1 mt-3"
            />
            <img
              src="/img/Agus.png"
              alt="Agustín Lemes"
              style={{
                objectFit: "cover",
                height: "4rem",
                width: "10rem",
                objectPosition: "center",
              }}
              className="img-fluid rounded m-1"
            />
            <img
              src="/img/Agus.png"
              alt="Agustín Lemes"
              style={{
                objectFit: "cover",
                height: "4rem",
                width: "10rem",
                objectPosition: "bottom",
              }}
              className="img-fluid rounded m-1"
            />
          </div>
          <p className="p-2 text-center">
            Welcome! <br /> <strong> I am Agustín Lemes. </strong> <br /> This
            page was started in a Bootcamp in
            <strong> Montevideo, Uruguay. </strong> <br /> I had one day to
            build the page. After of completing Bootcamp, <br /> I Updating it
            for four days, to get a Better Design and Sence. <br />
            <strong> Thank for your time :)!</strong>
          </p>
        </div>
        {/* Carousel */}
        <div
          id="carouselExampleIndicators"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
          </div>
          <div className="carousel-inner text-center">
            <div className="carousel-item active">
              <img
                style={{
                  objectFit: "cover",
                  height: "60vh",
                }}
                src="/img/Movie-Cruella.png"
                className="img-fluid"
                alt="Movie-Cruella"
              />
            </div>
            <div className="carousel-item ">
              <img
                style={{
                  objectFit: "cover",
                  height: "60vh",
                }}
                src="/img/Home.png"
                className="img-fluid"
                alt="Home"
              />
            </div>
            <div className="carousel-item ">
              <img
                style={{
                  objectFit: "cover",
                  height: "60vh",
                }}
                src="/img/Search.png"
                className="img-fluid"
                alt="Search"
              />
            </div>
            <div className="carousel-item ">
              <img
                style={{
                  objectFit: "cover",
                  height: "60vh",
                }}
                src="/img/Contact.png"
                className="img-fluid"
                alt="Contact"
              />
            </div>
            <div className="carousel-item ">
              <img
                style={{
                  objectFit: "cover",
                  height: "60vh",
                }}
                src="/img/Movie.png"
                className="img-fluid"
                alt="Movie"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon  border rounded border-danger bg-danger"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon border rounded border-success bg-success"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/* Technologies */}
        <h1 className="text-white text-center my-5 fs-3">Technologies</h1>
        <ul className="d-flex flex-row flex-wrap p-0 m-0 align-items-center justify-content-center fs-5">
          <li style={{ listStyle: "none", color: "#ffbf00" }} className="me-3">
            TMBD<span className="fs-6">(API)</span>
          </li>
          <li style={{ listStyle: "none", color: "#ffbf00" }} className="me-3">
            React
          </li>
          <li style={{ listStyle: "none", color: "#ffbf00" }} className="me-3">
            Bootstrap
          </li>
          <li style={{ listStyle: "none", color: "#ff34d1" }} className="me-3">
            Redux
          </li>{" "}
          <li style={{ listStyle: "none", color: "#ff34d1" }} className="me-3">
            JavaScript
          </li>{" "}
          <li style={{ listStyle: "none", color: "#ff34d1" }} className="me-3">
            HTML
          </li>
          <li style={{ listStyle: "none", color: "#ff34d1" }} className="me-3">
            CSS
          </li>
          <li style={{ listStyle: "none", color: "#ff34d1" }} className="me-3">
            Font Awesome
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AboutUs;
