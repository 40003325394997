import "../App.css";
import Hero from "../components/Hero/Hero";

function Categories() {
  return (
    <>
      <Hero page="Categories" />
    </>
  );
}

export default Categories;
