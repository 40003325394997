function ViewBox({ children, applyTop }) {
  let style = { top: applyTop ? getTop() : "14vh" };

  return (
    <div
      className="vh-100 position-absolute d-flex justify-content-center mx-auto vw-100 container-fluid"
      style={style}
    >
      {children}
    </div>
  );
}

export default ViewBox;

function getTop() {
  let top = "14vh"; // at lg

  if (window.innerWidth > 440) top = "12vh";
  if (window.innerWidth > 576) top = "51vh";
  if (window.innerWidth > 768) top = "72vh";
  if (window.innerWidth > 992) top = "58vh";

  return top;
}
