import Home from "./pages/Home";
import Categories from "./pages/Categories";
import MovieDetails from "./pages/MovieDetails";
import AboutUs from "./pages/AboutUs";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Router>
      <Route component={NavBar} />
      <div
        style={{ zIndex: "-1", backgroundColor: "#000" }}
        className="vw-100 vh-100 position-fixed"
      ></div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/categories" component={Categories} />
        <Route path="/movie/:id" component={MovieDetails} />
        <Route path="/about-us" component={AboutUs} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
