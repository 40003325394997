import Box from "../components/struct/Box";

function Loading({}) {
  return (
    <Box>
      <div className="spinner-border text-white" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </Box>
  );
}

export default Loading;
