import "../../App.css";
import Box from "./components/struct/Box";
import Row from "./components/struct/Row";
import usePeliculas from "./usePeliculas";
import Loading from "./views/Loading";
import Movie from "./views/Movie";
import NoResults from "./views/NoResults";
import ServerError from "./views/ServerError";

const Peliculas = () => {
  const getKey = (i, id) => i + "_" + id;
  const { movies, isLoading, hasError, noResults } = usePeliculas();

  if (isLoading) return <Loading />;
  if (hasError) return <ServerError />;
  if (noResults) return <NoResults />;

  return (
    <Box>
      <Row>
        {movies.map((m, i) => (
          <Movie key={getKey(i, m.id)} {...m} />
        ))}
      </Row>
    </Box>
  );
};

export default Peliculas;
